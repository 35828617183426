<template>
  <q-card>
    <div
        class="text-h6 text-bold text-center"
    >
      {{ $t('Box') + ':' }} {{ getActiveBox()['priority'] }}/{{ getBoxCount() }}
      <q-tooltip>{{ getActiveBox()['id'] }}</q-tooltip>
    </div>
  </q-card>
</template>

<script>
export default {
  name: 'PlacesCollection',
  emits: ['click'],
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '',
          name: 'details',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        complete: 'success',
        checking: 'warning',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark'
      }
    }
  },
  computed: {
    title () {
      if (this.rawData.options) {
        return this.rawData.options.title
      }

      return this.rawData.title || ''
    }
  },
  methods: {
    handleClick (data) {
      const event = {
        type: 'Orderadmin\\Storage\\Entity\\Warehouse',
        event: 'storage.warehouse.opened',
        data
      }

      this.$emit('click', event)
    },
    getActiveBox () {
      if (this.data.length === 1) {
        return this.data[0]
      } else {
        let box = Object.values(this.data.filter(element => element.state === 'filling'))[0]

        if (!box) {
          box = {
            priority: Object.values(this.data).length
          }
        }

        return box
      }
    },
    getBoxCount () {
      return this.data.length
    }
  }
}
</script>
